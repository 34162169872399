import {
  makeUrl,
  get,
} from '../../utils/fetcher';

import {
  METHODS,
} from '../../constants';

export const types = {
  RESET_PERSONA_FORM_VALUES: 'RESET_PERSONA_FORM_VALUES',
  SET_PERSONA_FORM_VALUES: 'SET_PERSONA',
  UPDATE_BASE_SETTING: 'UPDATE_PERSONA_BASE_SETTING',
  BIND_DOMAIN: 'BIND_PERSONA_DOMAIN',
  UPDATE_CONFIGURED_GROUPS: 'UPDATE_PERSONA_CONFIGURED_GROUPS',
  UPDATE_ENTRA_CONFIGURED_GROUPS: 'UPDATE_ENTRA_CONFIGURED_GROUPS',
  UPDATE_PERSONA_AD_OBSERVED_GROUPS: 'UPDATE_PERSONA_AD_OBSERVED_GROUPS',
  UPDATE_ENTRA_OBSERVED_GROUPS: 'UPDATE_ENTRA_OBSERVED_GROUPS',
  SET_DIRECTORY_SERVICES: 'SET_DIRECTORY_SERVICES',
  BIND_TENANT: 'BIND_PERSONA_TENANT',
  SET_ENTRA_ID: 'SET_ENTRA_ID',
  SET_TENANT_SYNC_TIME: 'SET_TENANT_SYNC_TIME',
};

export function updateBaseSetting(newSettings) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_BASE_SETTING,
      data: newSettings,
    });
  };
}

export function updateBaseSettingAndUnbind(newSettings) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_BASE_SETTING,
      data: newSettings,
    });
    dispatch({
      type: types.BIND_TENANT,
      tenant: {},
    });
  };
}

export function resetPersonaFormValues() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PERSONA_FORM_VALUES,
    });
  };
}

export function bindDomain(domain) {
  return (dispatch) => {
    dispatch({
      type: types.BIND_DOMAIN,
      domain,
    });
  };
}

export function getGroupsByDomain(domainId) {
  const url = makeUrl(
    METHODS.GET_AD_USER_GROUP_NAMES, {adDomainId: domainId},
  );
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch(updateObservedGroups(response.data));
      }
    });
}

export function updateObservedGroups(groups) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_PERSONA_AD_OBSERVED_GROUPS,
      groups,
    });
  };
}

export function updateEntraObservedGroups(groups) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ENTRA_OBSERVED_GROUPS,
      groups,
    });
  };
}

export function setUpdatedConfiguredGroups(data) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CONFIGURED_GROUPS,
      data,
    });
  };
}

export function setUpdatedEntraConfiguredGroups(data) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ENTRA_CONFIGURED_GROUPS,
      data,
    });
  };
}

export function updateDirectoryServices(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DIRECTORY_SERVICES,
      data,
    });
  };
}

export function updateEntraId(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ENTRA_ID,
      data,
    });
  };
}

export function bindTenant(tenant) {
  return (dispatch) => {
    dispatch({
      type: types.BIND_TENANT,
      tenant,
    });
  };
}

export function getGroupsAndSyncTimeByTenant(entraTenantId) {
  const url = makeUrl(
    METHODS.GET_ENTRA_GROUPS_AND_SYNC_TIME, {entraTenantId},
  );
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch(updateEntraObservedGroups(response.data.groups));
        dispatch({
          type: types.SET_TENANT_SYNC_TIME,
          data: response.data.lastSyncAt,
        });
      }
    });
}
